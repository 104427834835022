.footer {
    background-color: #2f2f2f;
    color: #fff;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateY(60px);
    
    border-radius:10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
    padding: .5rem 2rem 0.5rem 1rem;
    opacity: 0.9;
  }
  
  .footer.animated {
    transform: translateY(0);
  }
  