.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  z-index: 999;
}

  
.logo {
  width: 220px;
  height: 50px;
  padding: 0;
  margin: 0 auto;
}

  
.links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
.links a {
    color: #fff;
    font-size: 1.2rem;
    margin-left: 1.5rem;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .links a:hover {
    color: #ffc107;
  }
  
  #hidden {
    display: none;
  }
  
  .toggle-button {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .logo{
      margin: 0 auto;
    }
    
    .links {
      display: none;
    }
  
    .toggle-button {
      display: block;
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      z-index: 999;
      width: 30px;
      height: 25px;
      background-color: transparent;
      border: none;
      padding: 0;
    }
  
    .bar {
      display: block;
      height: 3px;
      width: 100%;
      margin: 5px auto;
      background-color: rgb(131,58,180);
      transition: all 0.3s ease-in-out;
    }
  
    .toggle-button.active .bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .toggle-button.active .bar:nth-child(2) {
      opacity: 0;
    }
  
    .toggle-button.active .bar:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }

  
    #hidden {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 998;
    }
  
    #hidden a {
      color: #fff;
      font-size: 1.5rem;
      margin: 1rem 0;
      text-decoration: none;
    }
  
    #hidden a:hover {
      color: #ffc107;
    }
  }
  .formcenter {
    margin-left: auto;
  }

  .search-bar {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: block;
    align-items: center;
  }
  
  .search-bar input {
    height: 30px;
    padding: 5px;
    margin-right: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  .search-bar button {
    height: 30px;
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .search-results {
    margin-top: 10px;
  }
  
  .search-results ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .search-results li {
    margin-bottom: 5px;
  }
  
  .search-results li a {
    color: #333;
    text-decoration: none;
    font-size: 16px;
  }
  
  .search-results li a:hover {
    text-decoration: underline;
  }
  