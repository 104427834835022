
html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #252527;
    background: #fd746c; /* fallback for old browsers */
    //background: -webkit-linear-gradient(to right, #68d9ff, #6cfdfd);
    //background: linear-gradient(to right, #68d9ff, #6cfdfd);
  }

  body {
    background: linear-gradient(90deg, rgba(111,111,121,1) 0%, rgba(212,212,227,1) 30%, rgba(193,197,217,1) 69%, rgba(176,224,235,1) 100%);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
   
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  a {
    color: inherit;
    text-decoration: underline;
  }
  
  a:active,
  a:hover {
    color: inherit;
  }
  
   
  .page-container {
    margin: 96px auto;
    padding: 0 40px;
    max-width: 800px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .posts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .post-card {
    width: 100%;
    background: rgba(0, 0, 0, 0.08);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  @media screen and (min-width: 500px) {
    .post-card {
      width: 48%;
    }
  }
  
  @media screen and (min-width: 800px) {
    .post-card {
      width: 32%;
    }
  }
  
  .post-card img {
    height: auto;
    max-height: 300px;
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: .5rem;
  }
  
  .post-card a {
    text-decoration: none;
    color: inherit;
  }
  
  .post-card h3 {
    font-size: 1.4rem;
    margin: 0 0 40px;
    word-wrap: break-word;
  }
  
  .post-card p {
    margin: 0;
    padding: 0;
  }
  
  .post-card p + p {
    margin-top: 0.25rem;
  }
  
  .text-bold {
    font-weight: 700;
  }
  
  .post-content img {
    height: auto;
    max-width: 100%;
  }
  
  .categories-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  iframe {
    display: block;
    margin: 0 auto;
 }
 .main-navigation ul li a, p,pre,sup, ol.footnotes li, div,li,blockquote p,ol.footnotes li, li a
 {
     font-size: 18px;
 }
 
 .main-navigation ul li .sub-arrow, .super-menu .toggle-mobile-menu, .toggle-mobile-menu:before, .mobile-menu-active .smenu-hide
 { font-size: 24px;}
 
 a:link
 {
     color: #108080;
 }
 a:visited { color: #4285F4;  }
 a:hover {
   color: #B60C0C;
  
 }
 .slider-featured-image a img, .widget_highlighted_posts img, .widget_featured_posts img, .single-related-posts img, .comment-content, .author-box {
     border-radius: 10px;
 }
 
 .featuredimage img{
      border-radius: 10px;
      width: 100%;
 }
 
 #toc_container {
     background-color: #f9f9f9;
     width: fit-content;
     border-radius:10px;
     box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
     padding: .5rem 2rem 0.5rem 2rem;
 }

 .toc_list{
  padding: .5rem 2rem 0.5rem 1rem;
 }
 .quotes_box_and_tips:before{
     border-radius:10px;
 }
 
 .quotes_box_and_tips{padding-right: 8px;
     padding-left: 8px;
     
 }

 .toc_title{
     padding: 10px 0 0 20px;
 }
 
 #content .post img{
     border-radius:10px;
     
 }
 blockquote,  figure{
  margin-inline-start: .1rem;
    margin-inline-end: .1rem;
 }
  
 blockquote,
 .wp-block-quote, .wp-block-quote.is-style-large, .wp-block-quote.has-text-align-right{
    
     border-left: 1px solid #428bca;
           color: #0e0f0e;
           background: #fcf2f2;
           border-radius: 8px;
           box-shadow: 0 0 0 1px rgba(53,72,91,.07), 0 2px 2px rgba(0,0,0,.01), 0 4px 4px rgba(0,0,0,.02), 0 10px 8px rgba(0,0,0,.03), 0 15px 15px rgba(0,0,0,.03), 0 30px 30px rgba(0,0,0,.04), 0 70px 65px rgba(0,0,0,.05);
       
     padding: 1rem 1rem 0.5rem 1.5rem;
    margin-bottom: 1rem;
      }
 blockquote p, .wp-block-quote p{font-size: 18px;
}
 
 blockquote p:before {
       color: #222;
     font-size: 22px;
 }
 blockquote a:link
 {
     color: green;
 }
 blockquote a:hover {
   color: red;
 }
 .footnotes
 {
     font-style: italic;
 }
 .wp-block-button__link{
     background-color: #cc3d64;
 }
 
 .footer-socket-wrapper .copyright{
 color: #cc3d64;	   
 }
 .footer-socket-wrapper .copyright a{
      color: #64c1ff;
 }
 #content .post .article-content .below-entry-meta .posted-on a, #content .post .article-content .below-entry-meta .byline a, #content .post .article-content .below-entry-meta .comments a, #content .post .article-content .below-entry-meta .tag-links a {font-size: 18px;}
 
 .slider-featured-image a img{
     width:725px;
     height:350px;
 }
 .has-background{border-radius: 0.8rem;}
 #secondary .widget{
     padding: 12px;
   border-radius: 5px;
   
   -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
   -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
 }
 
 .widget_featured_posts {
     padding: 12px;
   border-radius: 5px;
   
   -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
   -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
 }
 .tg-footer-main-widget, .tg-footer-other-widgets, .footer-socket-left-section, .footer-socket-right-section {
     padding: 12px;
   border-radius: 5px;
   
   -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
   -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
 }
 
 .footer-socket-left-section{
         float: left;
 }

 video, iframe {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  }

  .wp-block-code {
    white-space: normal;
    background: #edf6ff;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    border: 1px solid #aaa;
    padding: 10px;
    margin-bottom: 1em;
    width: auto;
    display: table;
    font-size: 95%;

}



 .has-background {
  padding: 2rem;
}

.has-black-color {
  color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
  color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}
